import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { RichText } from "prismic-reactjs";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Layout from "components/Layout";

const Hero = styled("div")`
    padding-top: 2.5em;
    padding-bottom: 0em;
    margin-bottom: 0em;
    max-width: 830px;

    @media(max-width:${dimensions.maxwidthMobile}px) {
       margin-bottom: 3em;
    }

    h1 {
        margin-bottom: 1em;

        }
    }
`

const Section = styled("div")`
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;

    @media(max-width:${dimensions.maxwidthTablet}px) {
        margin-bottom: 4em;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
    a {
        text-decoration: none;
        transition: all 100ms ease-in-out;

        &:nth-of-type(1) { color: ${colors.blue500}; }
        &:nth-of-type(2) { color: ${colors.orange500}; }
        &:nth-of-type(3) { color: ${colors.purple500}; }
        &:nth-of-type(4) { color: ${colors.green500}; }
        &:nth-of-type(5) { color: ${colors.teal500}; }

        &:hover {
            cursor: pointer;
            transition: all 100ms ease-in-out;

            &:nth-of-type(1) { color: ${colors.blue600};    background-color: ${colors.blue200};}
            &:nth-of-type(2) { color: ${colors.orange600};  background-color: ${colors.orange200};}
            &:nth-of-type(3) { color: ${colors.purple600};  background-color: ${colors.purple200};}
            &:nth-of-type(4) { color: ${colors.green600};   background-color: ${colors.green200};}
            &:nth-of-type(5) { color: ${colors.teal600};    background-color: ${colors.teal200};}

        }
`


const RenderBody = ({ about, meta }) => (
    <>
        <Helmet
            title={meta.title}
            titleTemplate={`%s | ${meta.title}`}
            meta={[
                {
                    name: `description`,
                    content: meta.description,
                },
                {
                    property: `og:title`,
                    content: meta.title,
                },
                {
                    property: `og:description`,
                    content: meta.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: meta.title,
                },
                {
                    name: `twitter:description`,
                    content: meta.description,
                },
            ].concat(meta)}
        />
        <Hero>
            <>
                {RichText.render(about.about_title)}
            </>

        </Hero>
        <Section>
            <img src={about.about_image.url} width="50%" alt="Headshot of Billie" />
        </Section>
        <Section>
            <>
                {RichText.render(about.about_text)}
            </>
        </Section>
    </>
);

export default ({ data }) => {
    //Required check for no data being returned
    const doc = data.prismic.allAbouts.edges.slice(0, 1).pop();
    const meta = data.site.siteMetadata;

    if (!doc) return null;

    return (
        <Layout>
            <RenderBody about={doc.node} meta={meta}/>
        </Layout>
    )
}

RenderBody.propTypes = {
    about: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    {
        prismic {
            allAbouts {
                edges {
                    node {
                        about_title
                        about_image
                        about_text
                    }
                }
            }
           
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`